<template>
    <painel :titulo="titulo" icone="pi pi-user" :refreshFunction="obterCheckin">
        <div class="mb-4">
            <detalhe titulo="Código" :size="size">
                {{ checkin?.codigoExterno }}
            </detalhe>
            <detalhe titulo="Participante" :size="size">
                {{ checkin?.participante }}
            </detalhe>
            <detalhe titulo="Data da Leitura" :size="size">
                {{ $dateFormat(checkin?.dataLeitura, 'DD/MM/YYYY HH:mm:SS') }}
            </detalhe>
            <detalhe titulo="Recebido em" :size="size">
                {{ $dateFormat(checkin?.dataCadastro, 'DD/MM/YYYY HH:mm:SS') }}
            </detalhe>
            <detalhe titulo="Status" :size="size">
                <status :status="checkin?.statusDescr"></status>
            </detalhe>
            <detalhe titulo="Status Integração" :size="size">
                <status :status="checkin?.statusIntegracaoInternaDescr"></status>
            </detalhe>
            <detalhe titulo="Protocolo" :size="size">
                {{ checkin?.protocolo }}
            </detalhe>
            <detalhe titulo="Data integração" :size="size">
                {{ $dateFormat(checkin?.dataIntegracaoInterna, 'DD/MM/YYYY HH:mm:SS') }}
            </detalhe>
            <detalhe titulo="Objeto Integração" :size="size">
                <vue-json-pretty
                    :data="JSON.parse(checkin?.objetoIntegracaoInterna)"
                    :showDoubleQuotes="true"
                    v-if="checkin?.objetoIntegracaoInterna && checkin?.objetoIntegracaoInterna?.isJson()"
                ></vue-json-pretty>
                <span v-if="checkin?.objetoIntegracaoInterna && !checkin?.objetoIntegracaoInterna?.isJson()">{{ checkin?.objetoIntegracaoInterna }}</span>
            </detalhe>
            <detalhe titulo="Retorno Integração" :size="size">
                <vue-json-pretty
                    :data="JSON.parse(checkin?.objetoRetornoIntegracaoInterna)"
                    :showDoubleQuotes="true"
                    v-if="checkin?.objetoRetornoIntegracaoInterna && checkin?.objetoRetornoIntegracaoInterna?.isJson()"
                ></vue-json-pretty>
                <span v-if="checkin?.objetoRetornoIntegracaoInterna && !checkin?.objetoRetornoIntegracaoInterna?.isJson()">{{
                    checkin?.objetoRetornoIntegracaoInterna
                }}</span>
            </detalhe>
            <detalhe titulo="Origem" :size="size">
                {{ obterDescricaoOrigem(checkin?.origem) }}
            </detalhe>
        </div>
        <btn-voltar :route="rotaRetorno"></btn-voltar>
    </painel>
</template>

<script>
import CheckinsService from './services';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import OrigemEnum from './OrigemEnum';
export default {
    props: {
        evento: {
            type: Object,
        },
    },

    components: {
        VueJsonPretty,
    },

    data() {
        return {
            checkin: null,
            activeIndex: 0,
            size: '140',
        };
    },

    methods: {
        obterCheckin() {
            this.$store.dispatch('addRequest');
            CheckinsService.obterPorId(this.$route.params.id, this.$route.params.participanteId, this.$route.params.checkinId).then((response) => {
                if (response?.success) {
                    this.checkin = response.data;
                } else {
                    this.checkin = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
        obterDescricaoOrigem(id) {
            if (id >= 0) {
                return OrigemEnum.ObterPorId(id).descricao;
            }
        },
    },

    computed: {
        rotaRetorno() {
            return {
                name: 'Checkins_Eventos_Detalhar',
                params: {
                    id: this.$route.params.id,
                },
            };
        },

        titulo() {
            if (this.evento && this.checkin) {
                return `Checkin - Evento: ${this.evento?.codigo} - ${this.evento?.nome} | Participante: ${this.checkin?.participante}`;
            }
            return 'Checkin - Evento: | Participante:';
        },
    },

    mounted() {
        this.obterCheckin();
    },
};
</script>
