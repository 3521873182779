export default class OrigemEnum {
    static ANDROID = { id: 0, descricao: 'Android' };
    static IOS = { id: 1, descricao: 'IOS' };
    static API = { id: 2, descricao: 'API' };

    static ObterValores() {
        return [OrigemEnum.ANDROID, OrigemEnum.IOS, OrigemEnum.API];
    }

    static ObterPorId(id) {
        return OrigemEnum.ObterValores().find((status) => status.id === id);
    }
}
